import React from "react";
import {
  Center,
  createStyles,
  Divider,
  Stack,
  Text,
  Title,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",

    padding: theme.spacing.xl * 1.5,
    borderRadius: theme.radius.md,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  title: {
    color: theme.white,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: theme.fontSizes.lg,
  },

  count: {
    color: theme.white,
    fontSize: 32,
    lineHeight: 1,
    fontWeight: 700,
    marginBottom: theme.spacing.md,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    fontSize: theme.fontSizes.sm,
    marginTop: 5,
  },

  stat: {
    flex: 1,

    "& + &": {
      paddingLeft: theme.spacing.xl,
      marginLeft: theme.spacing.xl,

      [theme.fn.smallerThan("sm")]: {
        paddingLeft: 0,
        marginLeft: 0,
        borderLeft: 0,
        paddingTop: theme.spacing.xl,
        marginTop: theme.spacing.xl,
        borderTop: `1px solid ${theme.colors[theme.primaryColor][3]}`,
      },
    },
  },
}));

interface StatsGroupProps {
  data: { title: string; stats: string | JSX.Element; description: string }[];
}

export default function GroupedStats({ data }: StatsGroupProps) {
  const { classes } = useStyles();
  const stats = data.map((stat) => (
    <div key={stat.title + "history"} className={classes.stat}>
      <Stack>
        <Center>
          <Title className={classes.title}>{stat.title}</Title>
        </Center>
        <Divider color="yellow" size={"md"} />
        <Center>
          <Text className={classes.count}>{stat.stats}</Text>
        </Center>
      </Stack>
    </div>
  ));
  return <div className={classes.root}>{stats}</div>;
}
