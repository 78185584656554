import React from 'react';

type loadingType = {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const initial: loadingType = { loading: false, setLoading: () => {} };

export const LoadingContext = React.createContext(initial);
