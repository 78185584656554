import { useState } from "react";
import {
  createStyles,
  Navbar,
  Group,
  Code,
  CheckboxGroup,
  Divider,
  Center,
  Title,
  Container,
  Text,
  Image,
  Box,
} from "@mantine/core";

import React from "react";
import Sidebar from "./Sidebar";

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    gone: {
      [theme.fn.smallerThan("sm")]: {
        display: "none",
      },
    },
  };
});

export default function MainSidebar() {
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(classes.gone)}>
      <Sidebar />
    </Box>
  );
}
