import {
  SimpleGrid,
  Container,
  Center,
  Loader,
  useMantineTheme,
  Text,
  Box,
} from "@mantine/core";
import GroupedStats from "components/GroupedStats";
import PodiumCard from "components/PodiumCard";
import Table from "components/Table";
import StakingModal from "components/StakingModal";
import Countdown from "react-countdown";
import React, { useContext } from "react";
import { Web3Context } from "hooks/useWeb3Context";
import { BigNumber, BigNumberish, ethers } from "ethers";
import { Leaderboard__factory, MDAO__factory } from "generated";
import {
  BUYBACK_PERCENT,
  LEADERBOARD_CONTRACT,
  NEXTBUYBACK,
  TOKEN_CONTRACT,
} from "util/constants";
import { showNotification, updateNotification } from "@mantine/notifications";
import { bn, fromWei } from "util/utils";
import BigNumberJS from "bignumber.js";
import { ERC20__factory } from "generated/factories/ERC20__factory";
import Web3Utils from "web3-utils";

import Fade from "react-reveal/Fade";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";

import {
  useNextBuyBack,
  useBuyBackWallet,
  useVotableTokens,
} from "util/v2memedaoWeb3";
import { Link } from "react-router-dom";

export type ModalData = {
  tokenContract: string;
  stakingContract: string;
  walletBalance?: BigNumberish;
  setTokens?: React.Dispatch<React.SetStateAction<number>>;
  tokens?: number;
};

export type TokenType = {
  name: string;
  symbol: string;
  amtStaked: BigNumber;
  percentStaked: string;
  tokenContract: string;
  stakingContract: string;
  totalSupply: BigNumber;
  amtContributed: BigNumber;
};

const Leaderboard = () => {
  const theme = useMantineTheme();
  const matches = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  const walletHook = useBuyBackWallet();
  const bbHook = useNextBuyBack();
  const tokens = useVotableTokens();

  const buybackWalletValue = walletHook.data ? walletHook.data : 0;
  const nextBuyBackValue = bbHook.data ? bbHook.data : 0;
  console.log(buybackWalletValue, "buybackWalletValue");

  const tokenDataUnsorted = tokens.data ? tokens.data : [];
  const tokenData = tokenDataUnsorted.sort((a, b) => {
    return Number.parseInt(b.tokenToVotes) - Number.parseInt(a.tokenToVotes);
  });

  const loaded =
    !walletHook.isLoading && !bbHook.isLoading && !tokens.isLoading;

  // check if a user has staked tokens in order to vote
  const stats = {
    data: [
      {
        title: "Buyback Wallet",
        stats: `${fromWei(buybackWalletValue, 2)} Ξ`,
        description: "",
      },
      {
        title: "Time Till Buyback",
        stats: (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Countdown date={new Date(NEXTBUYBACK)} />
            <Text color="dimmed">DD:HH:MM:SS</Text>
          </Box>
        ),
        description: "",
      },
      {
        title: "Next Buyback",
        stats: `${fromWei(nextBuyBackValue, 2)} Ξ`,
        description: "",
      },
    ],
  };
  // https://flexiple.com/react/conditional-rendering-in-react/
  // can i have multiple return statements?
  return (
    <>
      {!loaded && (
        <Center sx={{ height: "20vh" }}>
          <Loader color="yellow" />
        </Center>
      )}

      {NEXTBUYBACK > Date.now() && loaded && tokenData.length == 0 && (
        <Center mt={"lg"}>Loading...</Center>
      )}
      {loaded && tokenData.length > 0 && (
        <Fade>
          <>
            <GroupedStats data={stats.data} />
            {NEXTBUYBACK > Date.now() && (
              <>
                <Container px={0} mt={70}>
                  {matches && (
                    <SimpleGrid
                      cols={3}
                      breakpoints={[
                        { maxWidth: "md", cols: 3, spacing: "md" },
                        { maxWidth: "sm", cols: 1, spacing: "sm" },
                        { maxWidth: "xs", cols: 1, spacing: "sm" },
                      ]}
                    >
                      {tokenData[0] && (
                        <Container px={0} mx={0} py={20}>
                          <PodiumCard place={1} data={tokenData[0]} />
                        </Container>
                      )}
                      {tokenData[1] && (
                        <Container px={0} fluid mx={0}>
                          <PodiumCard place={2} data={tokenData[1]} />
                        </Container>
                      )}
                      {tokenData[2] && (
                        <Container px={0} fluid mx={0} py={20}>
                          <PodiumCard place={3} data={tokenData[2]} />
                        </Container>
                      )}
                    </SimpleGrid>
                  )}
                  {!matches && (
                    <SimpleGrid
                      cols={3}
                      breakpoints={[
                        { maxWidth: "md", cols: 3, spacing: "md" },
                        { maxWidth: "sm", cols: 1, spacing: "sm" },
                        { maxWidth: "xs", cols: 1, spacing: "sm" },
                      ]}
                    >
                      {tokenData[1] && (
                        <Container px={0} mx={0} py={20}>
                          <PodiumCard place={2} data={tokenData[1]} />
                        </Container>
                      )}
                      {tokenData[0] && (
                        <Container px={0} fluid mx={0}>
                          <PodiumCard place={1} data={tokenData[0]} />
                        </Container>
                      )}
                      {tokenData[2] && (
                        <Container px={0} fluid mx={0} py={20}>
                          <PodiumCard place={3} data={tokenData[2]} />
                        </Container>
                      )}
                    </SimpleGrid>
                  )}
                </Container>
                {tokenData[3] && <Table data={tokenData.slice(3)} />}
              </>
            )}
            {NEXTBUYBACK < Date.now() && loaded && (
              <Center mt={"lg"}>
                Leaderboard timer expired! Please check out the&nbsp;
                <Link to="/buyback" color="gold" style={{ color: "gold" }}>
                  {" "}
                  buyback history
                </Link>
                &nbsp; page for info on the last buyback.
              </Center>
            )}
          </>
        </Fade>
      )}
    </>
  );
};

export default Leaderboard;
