import { BigNumber, BigNumberish } from 'ethers';
import Web3Utils from 'web3-utils';

export const onChangeForm = (event: any) => (setState: (x: any) => void) => setState(event.target.value);

export const numberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const truncateText = (text: string) => {
  return text.replace(/^(.{70}[^\s]*).*/, '$1') + '..';
};

export const bn = (b: BigNumberish) => {
  return BigNumber.from(b);
};

export const fromWei = (b: BigNumberish, sig: number = 3) => {
  let raw = Web3Utils.fromWei(bn(b).toString());
  if (raw.includes('.') && raw.split('.')[1].length < sig) {
    raw =
      numberWithCommas(parseInt(raw.split('.')[0])) +
      '.' +
      raw.split('.')[1] +
      ''.padStart(sig - raw.split('.')[1].length, '0');
  } else if (raw.includes('.')) {
    raw = numberWithCommas(parseInt(raw.split('.')[0])) + '.' + raw.split('.')[1].substring(0, sig);
  } else if (sig !== 0) {
    raw = numberWithCommas(parseInt(raw)) + '.' + ''.padStart(sig, '0');
  }
  return raw;
};

export const fromWeiNoDecimal = (b: BigNumberish) => {
  let raw = Web3Utils.fromWei(bn(b).toString());
  if (raw.includes('.')) {
    return raw.split('.')[0];
  }
  return raw;
};

export const calcPercent = (divide: BigNumberish, by: BigNumberish, sig: number = 3) => {
  if (bn(by).eq(bn(0))) {
    return '0.' + ''.padStart(sig, '0') + '%';
  }
  if (bn(divide).eq(bn(by))) {
    return '100%';
  }
  // reg percent
  let regP = bn(divide).mul(100).div(bn(by)).toString();

  let sigD = bn(100).mul(bn(10).pow(sig));
  let res = bn(divide).mul(sigD).div(bn(by)).toString();
  console.log('res', res);

  // 0% or if sig digit is 0 can just return
  if (res === '0' || sig === 0) {
    if (sig > 0) {
      return res + '.' + ''.padStart(sig, '0') + '%';
    }
    return res + '%';
  }
  // pad string in the case where
  // 0.003%
  res = res.padStart(sig + 1, '0');
  // single digit percent, decimal goes to 1 instead of 2
  if (regP.length === 1) {
    return res.slice(0, 1) + '.' + res.slice(1) + '%';
  }
  // double digit percent, decimal goes to 2
  return res.slice(0, 2) + '.' + res.slice(2) + '%';
};
