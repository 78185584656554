import { useNavigate } from "react-router-dom";

import {
  createStyles,
  Title,
  Text,
  Button,
  Container,
  Group,
} from "@mantine/core";
import { WalletButton } from "components/Header";
import { Web3Context } from "hooks/useWeb3Context";
import React from "react";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  label: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl * 1.5,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[2],

    [theme.fn.smallerThan("sm")]: {
      fontSize: 120,
    },
  },

  title: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));

export default function PleaseConnectWallet() {
  const { loadWeb3Modal, logoutOfWeb3Modal, provider } =
    React.useContext(Web3Context);

  const { classes } = useStyles();
  let history = useNavigate();

  return (
    <Container className={classes.root}>
      <div className={classes.label}>‎ </div>
      <Title className={classes.title}>Connect Wallet To See Dashboard</Title>
      <Group position="center" sx={{ paddingTop: "20px" }}>
        <WalletButton
          provider={provider}
          loadWeb3Modal={loadWeb3Modal}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
        />
      </Group>
    </Container>
  );
}
