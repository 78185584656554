// testnet
// export const TOKEN_CONTRACT = "0x62521B1f45D5c186c1fD147487057e8A81F910eA";
// export const LEADERBOARD_CONTRACT =
//   "0x95756aE7bDf71D43b5bDD8C8695311bFA173Fe8d";
export const TOKEN_CONTRACT = "0xf6043bEa98B07F0bEa7813aeB22D0cb70C91C0c4";
export const LEADERBOARD_CONTRACT =
  "0x29030d515bd6aAAb3ec7c68B00955B642C8C5815";

export const BUYBACK_WALLET = "0x29030d515bd6aAAb3ec7c68B00955B642C8C5815";
// https://freetools.textmagic.com/timestamp-converter#:~:text=One%20millisecond%20%3D%201%2F1000%20in,second%20%3D%201%20in%20UNIX%20time
export const NEXTBUYBACK = 1677250800000;

export const BUYBACK_PERCENT = 0.2;
export const TREASURY_WALLET = "0x9693d0914d8BA76AAe0009416655ee29FD4C7a2d";

///

export const DEXTOOLS =
  "https://www.dextools.io/app/en/ether/pair-explorer/0xd769f8e857f431d2de4a02ac6b2c74998f705c7e";
export const TWITTER = "https://twitter.com/mdao_ai";
export const UNISWAP =
  "https://app.uniswap.org/#/swap?outputCurrency=0xf6043bEa98B07F0bEa7813aeB22D0cb70C91C0c4&chain=mainnet";
export const TELEGRAM = "https://t.me/MDAI_Portal";

export const HEADER_DATA = [
  {
    link: "/",
    label: "Leaderboard",
  },
  {
    link: "/history",
    label: "History",
  },
  {
    link: "/staking",
    label: "Staking",
  },
  {
    link: "/claim",
    label: "Claim",
  },
];

export const FOOTER_DATA = [
  {
    title: "Company",
    links: [
      { label: "Tokenomics", link: "#" },
      { label: "Roadmap", link: "#" },
      { label: "Staking Tiers", link: "#" },
    ],
  },
  {
    title: "Help",
    links: [
      { label: "About Us", link: "#" },
      { label: "Terms", link: "#" },
    ],
  },
  {
    title: "Community",
    links: [
      { label: "Join Discord", link: "#" },
      { label: "Follow on Twitter", link: "#" },
      { label: "Telegram Group", link: "#" },
    ],
  },
];
export const SIDEBAR_DATA = [
  {
    title: "Dashboard",
    links: [
      {
        link: "https://meme-dao.ai/",
        label: "Home",
        icon: "/icons/home.png",
        external: true,
      },
      { link: "/stake", label: "Stake", icon: "/icons/stake.png" },
      {
        link: "/leaderboard",
        label: "Leaderboard",
        icon: "/icons/board.png",
      },
      { link: "/claim", label: "Claim", icon: "/icons/coin.png" },
      {
        link: "/buyback",
        label: "Buyback History",
        icon: "/icons/history.png",
      },
      { link: "/nft", label: "NFT", icon: "/icons/nftcoin.png" },
      {
        link: "https://meme-dao.ai/whitepaper.pdf",
        external: true,
        label: "Whitepaper",
        icon: "/icons/document.png",
      },
    ],
  },
  {
    title: "Action",
    links: [
      {
        link: UNISWAP,
        label: "Buy MDAI",
        external: true,
        icon: "/icons/bag.png",
      },
    ],
  },
  {
    title: "Info",
    links: [
      {
        link: TELEGRAM,
        label: "Telegram",
        external: true,
        icon: "/icons/tg.png",
      },
      {
        link: TWITTER,
        label: "Twitter",
        external: true,
        icon: "/icons/twitter.png",
      },
      {
        link: DEXTOOLS,
        label: "Dextools",
        external: true,
        icon: "/icons/dex.png",
      },
    ],
  },
];
