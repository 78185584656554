import React, { useContext } from "react";
import {
  Box,
  createStyles,
  Text,
  Title,
  Container,
  Button,
} from "@mantine/core";
import { useListState } from "@mantine/hooks";
import { ModalData, TokenType } from "pages/Leaderboard";

import { unstake } from "util/memedaoWeb3";
import { Web3Context } from "hooks/useWeb3Context";
import { bn, calcPercent, fromWeiNoDecimal } from "util/utils";
import { VotableTokenType } from "util/v2memedaoWeb3";

const abbreviate = require("number-abbreviate");

const useStyles = createStyles((theme) => ({
  item: {
    ...theme.fn.focusStyles(),

    display: "flex",
    alignItems: "center",
    borderRadius: theme.radius.md,
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: `${theme.spacing.sm}px ${theme.spacing.xl}px`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,
    marginBottom: theme.spacing.sm,
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  symbol: {
    fontSize: 30,
    fontWeight: 700,
    width: 60,
  },
}));

export interface LeaderboardTableRowProps {
  data: VotableTokenType[];
}

export default function Table({ data }: { data: VotableTokenType[] }) {
  const { classes, cx } = useStyles();

  const items = data.map((item, index) => (
    <div
      className={cx(classes.item, {
        [classes.itemDragging]: false,
      })}
      key={item.address + item.symbol}
    >
      <Container mx={0} fluid sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Title pr={"sm"}>{index + 4}.</Title>
            <Title>{item.symbol}</Title>
          </Box>
          <Box sx={{ flexWrap: "nowrap", whiteSpace: "nowrap" }}>
            <Title order={4} sx={(theme) => ({ color: theme.colors.dark[2] })}>
              {item.totalVotes == "NaN" || item.tokenVotes == "NaN"
                ? "0%"
                : calcPercent(item.tokenVotes, item.totalVotes, 2)}
              Supply Staked
            </Title>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Title
                order={4}
                sx={(theme) => ({ color: theme.colors.dark[2] })}
              >
                {item.name}
              </Title>
            </Box>
            <Box>
              <Text>
                Total Staked:{" "}
                {(
                  abbreviate(
                    fromWeiNoDecimal(item.tokenToVotes.toString()),
                    2
                  ) as string
                )
                  .toString()
                  .toUpperCase()}{" "}
                MDAI
              </Text>
              <Text>
                Your Contribution:{" "}
                {/* if token voted for is the same as the current token */}
                {item.userToToken == item.address
                  ? (
                      abbreviate(
                        fromWeiNoDecimal(item.balanceOf.toString()),
                        2
                      ) as string
                    )
                      .toString()
                      .toUpperCase()
                  : 0}{" "}
                MDAI
              </Text>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              justifyContent: "flex-end",
            }}
          >
            <Button
              color={"yellow"}
              mb={10}
              variant="light"
              sx={{
                WebkitBoxShadow: "0px 0px 116px 15px rgba(56,255,46,0.9)",
                MozBoxShadow: "0px 0px 116px 15px rgba(56,255,46,0.9)",
                boxShadow: "0px 0px 186px 1px rgba(224,255,46,0.35)",
              }}
              size={"md"}
              onClick={() => {
                item.vote();
              }}
            >
              Vote
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  ));

  return <div>{items}</div>;
}
