import { Center, Loader, Title } from "@mantine/core";

import HistoryTable from "components/HistoryTable";

import { Web3Context } from "hooks/useWeb3Context";
import React, { useContext } from "react";

import Fade from "react-reveal/Fade";
import { BuyBackEvent, useBuyBackEvents } from "util/v2memedaoWeb3";

const table = {
  data: [
    {
      tx: "0x044710377064aae1d6cea4eff6201be86d1f2dc63bcbf00aec8aa3b27cd0f27b",
      value: "29 ETH",
      symbol: "$AACA",
      name: "alphaAlpha",
      date: "04/03/2022",
    },
    {
      tx: "0x0b8710377064aae1d6cea4eff6201be86d1f2dc63bcbf00aec8aa3b27cd0f27b",
      value: "5 ETH",
      symbol: "$APE",
      name: "ApeCoin",
      date: "04/04/2022",
    },
    {
      tx: "0x0b8710377064aae1d6cea4eff6201be86d1f2dc63bcbf00aec8aa3b27cd0f27b",
      value: "5 ETH",
      symbol: "$APE",
      name: "ApeCoin",
      date: "04/04/2022",
    },
    {
      tx: "0x0b8710377064aae1d6cea4eff6201be86d1f2dc63bcbf00aec8aa3b27cd0f27b",
      value: "5 ETH",
      symbol: "$APE",
      name: "ApeCoin",
      date: "04/04/2022",
    },
  ],
};

export type BuybackType = BuyBackEvent;
const History = () => {
  const { account, provider } = useContext(Web3Context);
  const buyback = useBuyBackEvents();

  const loaded = !buyback.isLoading;

  return (
    <>
      <Title mb={"md"}>Buyback History</Title>
      {!loaded && (
        <Center sx={{ height: "20vh" }}>
          <Loader color="yellow" />
        </Center>
      )}
      {loaded && (
        <Fade>
          <>
            {buyback.data && <HistoryTable data={buyback.data} />}
            {!buyback.data && (
              <Center sx={{ height: "20vh" }}>
                <Title>No buybacks yet</Title>
              </Center>
            )}
          </>
        </Fade>
      )}
    </>
  );
};

export default History;
