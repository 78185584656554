import React from "react";
import { Web3Provider } from "@ethersproject/providers";

type Web3Type = {
  account: string;
  setAccount: React.Dispatch<React.SetStateAction<string>>;
  provider: Web3Provider | undefined;
  setProvider: React.Dispatch<React.SetStateAction<Web3Provider | undefined>>;

  loadWeb3Modal: any;
  logoutOfWeb3Modal: () => Promise<void>;

  setLoadWeb3Modal: any;
  setLogoutOfWeb3Modal: React.Dispatch<
    React.SetStateAction<() => Promise<void>>
  >;
};

const initial: Web3Type = {
  account: "",
  setAccount: () => {},
  provider: undefined,
  setProvider: () => {},
  loadWeb3Modal: undefined,
  logoutOfWeb3Modal: async () => {},
  setLoadWeb3Modal: () => {},
  setLogoutOfWeb3Modal: () => {},
};

export const Web3Context = React.createContext(initial);
