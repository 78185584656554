import { useState } from "react";
import {
  createStyles,
  Navbar,
  Group,
  Code,
  CheckboxGroup,
  Divider,
  Center,
  Title,
  Container,
  Text,
  Image,
} from "@mantine/core";

import { Link, useLocation } from "react-router-dom";

import { SIDEBAR_DATA } from "util/constants";
import { WalletButton } from "./Header";
import useWeb3Modal from "hooks/useWeb3Modal";
import { Web3Context } from "hooks/useWeb3Context";
import React from "react";

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef("icon");
  return {
    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[1]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === "dark" ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
      height: "18px",
      width: "18px",
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor: theme.fn.variant({
          variant: "light",
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
        [`& .${icon}`]: {
          color: theme.fn.variant({
            variant: "light",
            color: theme.primaryColor,
          }).color,
        },
      },
    },
  };
});

const data = [
  { link: "/", label: "Home" },
  { link: "/stake", label: "Stake" },
  { link: "/leaderboard", label: "Leaderboard" },
  { link: "/claim", label: "Claim" },
  { link: "/buyback", label: "Buyback History" },
  { link: "/", label: "NFT" },
  { link: "/", label: "Whitepaper" },
];

export default function Sidebar() {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("/");
  const { loadWeb3Modal, logoutOfWeb3Modal, provider } =
    React.useContext(Web3Context);

  const location = useLocation();

  React.useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  const genLink = (data: any) => {
    return data.map((item: any) => {
      if (item.external) {
        return (
          <a
            className={cx(classes.link)}
            href={item.link}
            key={item.label}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            {item.icon && (
              <Image className={classes.linkIcon} src={item.icon} />
            )}
            <Text>{item.label}</Text>
          </a>
        );
      }
      return (
        <Link
          className={cx(classes.link, {
            [classes.linkActive]: item.link === active,
          })}
          to={item.link}
          key={item.label}
          onClick={(event) => {
            setActive(item.label);
          }}
        >
          <>
            {item.icon && (
              <Image className={classes.linkIcon} src={item.icon} />
            )}
            <Text
              sx={{
                ...(item.label === active && { fontWeight: "bold" }),
                color: "white",
              }}
            >
              {item.label}
            </Text>
          </>
        </Link>
      );
    });
  };

  const links = genLink(data);

  const navSections = SIDEBAR_DATA.map((section: any) => (
    <Navbar.Section key={section.title}>
      <Container>
        <Text color={"gray"}>{section.title}</Text>
        {genLink(section.links)}
      </Container>
      <Center>
        <Divider
          my="lg"
          variant="dashed"
          color="yellow"
          size="xs"
          sx={{ width: "85%" }}
        />
      </Center>
    </Navbar.Section>
  ));

  return (
    <Navbar
      height={"100%"}
      width={{ sm: 300 }}
      p="md"
      sx={{ borderRightColor: "yellow" }}
    >
      {navSections}
      <Navbar.Section grow>
        <Center>
          <WalletButton
            provider={provider}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
          ></WalletButton>
        </Center>
      </Navbar.Section>
    </Navbar>
  );
}
