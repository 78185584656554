import { BigNumber, BigNumberish, ethers } from "ethers";
import { MDAO__factory } from "generated";

import { useQuery } from "react-query";

import {
  BUYBACK_WALLET,
  LEADERBOARD_CONTRACT,
  NEXTBUYBACK,
  TOKEN_CONTRACT,
  TREASURY_WALLET,
} from "./constants";
import { bn } from "./utils";

//@ts-ignore
import Units from "ethereumjs-units";
import { showNotification, updateNotification } from "@mantine/notifications";

import { ERC20__factory, Leaderboard__factory } from "generated/factories";
import { MultiRewards__factory } from "generated/factories";

import { CheckIcon } from "@modulz/radix-icons";
import { ReactNode, useContext } from "react";

import { Web3Context } from "hooks/useWeb3Context";
import Countdown from "react-countdown";

import { fromWei } from "web3-utils";

/** NOTES
 * any number amount should be converted to wei before being passed into the functions
 * any number amount returned from the functions will be in wei
 */

/**
 * UPDATED V2 FUNCTIONS
 *
 * updated functions to use v2 contracts
 * memedaoWeb3.ts -> v2memedaoWeb3.ts
 *
 * checkAllowance -> useCheckAllowance
 * updateAllowance -> useUpdateAllowance
 *
 * for example, if the UI originally used checkAllowance, it will now use useCheckAllowance
 * make sure to update function parameters as well as old parameters will no longer be compatible
 *
 * Notes:
 * - you MUST call updateAllowance before calling stake
 *
 */

export type allowanceProps = {
  provider: ethers.providers.Web3Provider | undefined;
  account: string;
  STAKING_CONTRACT_ADDRESS: string;
};
export const checkAllowance = async ({
  provider,
  account,
  STAKING_CONTRACT_ADDRESS,
}: allowanceProps) => {
  if (!STAKING_CONTRACT_ADDRESS) {
    return undefined;
  }
  if (!provider || !ethers.utils.isAddress(account)) {
    console.log("no provider or wallet provided");
    return true;
  }
  const signer = provider.getSigner();
  const MDAO = MDAO__factory.connect(TOKEN_CONTRACT, signer);

  // check if allowance has been increased
  // returns true if allowance < max amount
  // true if allowed
  let allowance = await MDAO.allowance(account, STAKING_CONTRACT_ADDRESS);
  console.log("their allowance is", allowance.toString());
  return bn(allowance).lte(bn(Units.convert(10000000000, "eth", "wei")));
};
export const updateAllowance = async ({
  provider,
  account,
  STAKING_CONTRACT_ADDRESS,
}: allowanceProps) => {
  if (!STAKING_CONTRACT_ADDRESS) {
    return;
  }
  if (!provider || !ethers.utils.isAddress(account)) {
    console.log("no provider or wallet provided");
    return true;
  }
  const signer = provider.getSigner();
  const MDAO = MDAO__factory.connect(TOKEN_CONTRACT, signer);
  // increase allowance
  try {
    let res = await MDAO.approve(
      STAKING_CONTRACT_ADDRESS!,
      Units.convert("100000000000", "eth", "wei")
    );
    showNotification({
      id: "load-data",
      loading: true,
      title: "Approving Wallet...",
      message: "Please do not leave this page until wallet has been approved!",
      autoClose: false,
      disallowClose: true,
    });
    console.log("approving wallet");

    await res.wait(1);
    updateNotification({
      id: "load-data",
      color: "teal",
      title: "Wallet approved.",
      message: "Wallet successfully approved! Feel free to close this message",
      autoClose: false,
      icon: CheckIcon({ color: "white" }),
    });
    console.log("wallet approved");
  } catch (e: any) {
    showNotification({
      title: "Unable to approve wallet.",
      message: e.message,
    });
    console.log("unable to approve", e.message);
  }
  return true;
};

/**
 * UPDATED V2 STAKING FUNCTIONS
 * Staking now holds a different meaning than staking in V1.
 *
 * In V1, users staked into different contracts as their "vote".
 *
 * V2 breaks this up into two steps:
 * In V2, users
 *    - stake into a single contract (first stake must include a vote)
 *    - place votes separately
 *
 * again,
 *
 * updated functions to use v2 contracts
 * memedaoWeb3.ts -> v2memedaoWeb3.ts
 *
 * stake -> useStake
 * unstake -> useUnstake
 *
 *
 * UI recommendations:
 * - separate pages for staking and voting
 *
 * - staking page should have a button to stake and a button to unstake
 * - staking page should have a dropdown to select which token to initially vote for
 * - staking page should have a disclaimer that previous votes will be moved to the most recent vote
 *
 * - voting page should be similar to the current Leaderboard page
 * - voting page should have cards for each token
 * - in each card, there should be a button to vote for that token
 * - voting page should have a disclaimer that previous votes will be moved to the most recent vote
 * - the token that the user has voted for should be highlighted, and the button should be disabled (or removed, as it is redundant)
 */

export type StakeProps = {
  provider: ethers.providers.Web3Provider | undefined;
  account: string;
  tokensToStake: BigNumber;
  voteTokenContract?: string;
  STAKING_CONTRACT_ADDRESS?: string;
};

export const stake = async ({
  tokensToStake,
  voteTokenContract,
  provider,
  account,
  STAKING_CONTRACT_ADDRESS,
}: StakeProps) => {
  if (!STAKING_CONTRACT_ADDRESS) {
    return;
  }
  if (!provider || !ethers.utils.isAddress(account)) {
    console.log("no provider or wallet provided");
    return true;
  }

  if (await checkAllowance({ provider, account, STAKING_CONTRACT_ADDRESS })) {
    console.log("allowance not set");
    await updateAllowance({ provider, account, STAKING_CONTRACT_ADDRESS });
  }

  const signer = provider.getSigner();

  const STAKING = MultiRewards__factory.connect(
    STAKING_CONTRACT_ADDRESS!,
    signer
  );
  const ERC20 = ERC20__factory.connect(voteTokenContract!, signer);
  if (tokensToStake.lte(0)) {
    showNotification({ title: "Can't stake 0 tokens", message: "" });
    return false;
  }

  try {
    let res = await STAKING.stake(tokensToStake, voteTokenContract!);
    showNotification({
      title: `Staking tokens and voting for token ${await ERC20.symbol()}}`,
      message: "",
    });
    await res.wait(1);
    return true;
  } catch (e: any) {
    showNotification({
      title: "Unable to stake",
      message: e.message,
    });
    console.log("TX failed", tokensToStake.toString(), e.message);
    return false;
  }
};

export const unstake = async ({
  tokensToStake,
  provider,
  account,
  STAKING_CONTRACT_ADDRESS,
}: StakeProps) => {
  if (!STAKING_CONTRACT_ADDRESS) {
    return;
  }
  if (!provider || !ethers.utils.isAddress(account)) {
    console.log("no provider or wallet provided");
    return true;
  }
  const signer = provider.getSigner();

  const STAKING = MultiRewards__factory.connect(
    STAKING_CONTRACT_ADDRESS,
    signer
  );
  if (tokensToStake.lte(0)) {
    showNotification({ title: "Can't unstake 0 tokens", message: "" });
    return false;
  }

  try {
    let res = await STAKING.withdraw(tokensToStake);
    showNotification({
      title: "Unstaking tokens.",
      message: "Corresponding votes have been removed.",
    });
    await res.wait(1);
    return true;
  } catch (e: any) {
    showNotification({
      title: "Unable to unstake",
      message: e.message,
    });
    console.log("TX failed", tokensToStake.toString(), e.message);
    return false;
  }
};

/**
 *  NEW V2 VOTING FUNCTIONS
 *
 *  I said this earlier in the comment block for staking functions, but:
 * - remember that voting votes all of your staked tokens
 * - make sure to communicate this to the user on the frontend
 *
 * useVotableTokens gives you everything you need to build out the voting/leaderboard page
 *
 * useVote is the function that you'll use to vote for a token, and it takes in the address of the token you want to vote for.
 *
 * UI Recommendation:
 *  - get the list of votable tokens from useVotableTokens
 * - for each token, build a card with a button that calls useVote with the token's address
 */

export type VoteProps = {
  provider: ethers.providers.Web3Provider | undefined;
  account: string;
  voteTokenContract: string;
  STAKING_CONTRACT_ADDRESS: string;
};

export const vote = async ({
  voteTokenContract,
  provider,
  account,
  STAKING_CONTRACT_ADDRESS,
}: VoteProps) => {
  if (!STAKING_CONTRACT_ADDRESS) {
    return;
  }
  if (!provider || !ethers.utils.isAddress(account)) {
    console.log("no provider or wallet provided");
    return true;
  }
  const signer = provider.getSigner();

  const STAKING = MultiRewards__factory.connect(
    STAKING_CONTRACT_ADDRESS!,
    signer
  );
  const ERC20 = ERC20__factory.connect(voteTokenContract, signer);

  try {
    let res = await STAKING.changeVote(voteTokenContract);
    showNotification({
      title: `Voting for token ${await ERC20.symbol()}}`,
      message: "",
    });
    await res.wait(1);
    return true;
  } catch (e: any) {
    showNotification({
      title: "Unable to vote",
      message: e.message,
    });
    console.log("TX failed", e.message);
    return false;
  }
};

export type VotableTokenType = {
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  // same thing as token to votes
  tokenVotes: string;
  vote: () => Promise<boolean | undefined>;
  stake: (tokensToStake: BigNumber) => Promise<boolean | undefined>;
  unstake: (tokensToStake: BigNumber) => Promise<boolean | undefined>;

  // user specific data

  // usertotoken, given a user, returns the token they voted for
  userToToken: string;

  // tokentovotes, given a token, returns the total votes for that token
  tokenToVotes: string;

  // balanceof, amount of tokens the user has
  balanceOf: string;

  // total votes
  totalVotes: string;
};

export const useVotableTokens = () => {
  const { provider, account } = useContext(Web3Context);
  const STAKING_CONTRACT_ADDRESS = useStakingContract().data;

  return useQuery(
    ["votableTokens", account, STAKING_CONTRACT_ADDRESS],
    async () => {
      if (!STAKING_CONTRACT_ADDRESS) {
        return;
      }

      if (!provider || !ethers.utils.isAddress(account)) {
        console.error("No provider or account");
        return;
      }

      const signer = provider.getSigner();
      const STAKING = MultiRewards__factory.connect(
        STAKING_CONTRACT_ADDRESS!,
        signer
      );

      let votableTokens: VotableTokenType[] = [];

      try {
        const votableTokenCount = await (
          await STAKING.rewardTokensCount()
        ).toNumber();

        // calc total votes
        let totalVotes = BigNumber.from(0);
        for (let i = 0; i < votableTokenCount; i++) {
          const token = await STAKING.rewardTokens(i);
          const tokenVotes = await STAKING.tokenToVotes(token);
          totalVotes = totalVotes.add(tokenVotes);
        }

        for (let i = 0; i < votableTokenCount; i++) {
          const tokenAddress = await STAKING.rewardTokens(i);
          const ERC20 = ERC20__factory.connect(tokenAddress, signer);
          const symbol = await ERC20.symbol();
          const tokenVotes = await STAKING.tokenToVotes(tokenAddress);
          const balanceOf = await STAKING.balanceOf(account);

          const name = await ERC20.name();
          const decimals = await ERC20.decimals();
          const userToToken = await STAKING.userToToken(account);
          const tokenToVotes = await STAKING.tokenToVotes(tokenAddress);

          votableTokens.push({
            address: tokenAddress,
            symbol,
            name,
            tokenVotes: tokenVotes.toString(),
            balanceOf: balanceOf.toString(),
            userToToken,
            tokenToVotes: tokenToVotes.toString(),
            totalVotes: totalVotes.toString(),
            decimals,
            vote: () =>
              vote({
                voteTokenContract: tokenAddress,
                provider,
                account,
                STAKING_CONTRACT_ADDRESS: STAKING_CONTRACT_ADDRESS!,
              }),
            stake: (tokensToStake: BigNumber) =>
              stake({
                voteTokenContract: tokenAddress,
                provider,
                account,
                tokensToStake,
                STAKING_CONTRACT_ADDRESS: STAKING_CONTRACT_ADDRESS!,
              }),
            unstake: (tokensToStake: BigNumber) =>
              unstake({
                provider,
                account,
                tokensToStake,
                STAKING_CONTRACT_ADDRESS: STAKING_CONTRACT_ADDRESS!,
              }),
          });
        }
      } catch (e) {
        console.error(e);
      }

      return votableTokens;
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );
};

/**
 * UPDATED V2 CLAIMING FUNCTIONS
 *
 * again,
 *
 * updated functions to use v2 contracts
 * memedaoWeb3.ts -> v2memedaoWeb3.ts
 *
 * claim -> useClaim
 *
 * useClaim claims all of unclaimed tokens for a user
 *
 * useUnclaimedTokens returns a list votable tokens and the amount of unclaimed tokens for each token
 *
 * useClaimEvents returns a list of all claim events for a user
 *
 * UI Recommendation:
 * - call useClaim when the user clicks a button to claim all of their unclaimed tokens
 * - make sure to communicate to the user that they are claiming all of their unclaimed tokens
 * - call useUnclaimedTokens to get list of unclaimed tokens, and display them to the user
 *
 * - call useClaimEvents to get a list of all claim events for a user
 * - for each event, build a card and display them to the user
 */

export type ClaimProps = {
  account: string;
  provider: ethers.providers.Web3Provider | undefined;
  STAKING_CONTRACT_ADDRESS: string;
};

export const claim = async ({
  account,
  provider,
  STAKING_CONTRACT_ADDRESS,
}: ClaimProps) => {
  if (!STAKING_CONTRACT_ADDRESS) {
    return;
  }
  if (!provider || !ethers.utils.isAddress(account)) {
    console.log("no provider or wallet provided");
    return;
  }
  const signer = provider.getSigner();

  const STAKING = MultiRewards__factory.connect(
    STAKING_CONTRACT_ADDRESS!,
    signer
  );

  try {
    let res = await STAKING.getReward();

    showNotification({
      title: "Successful Claim",
      message: `Claimed tokens!`,
    });
    await res.wait(1);
    return true;
  } catch (e: any) {
    showNotification({
      title: "Unable to Claim",
      message: e.message,
    });
    console.log("TX failed", e.message);
    return false;
  }
};

export type UnclaimedAmtType = {
  symbol: string;
  amount: string;
  address: string;
  claim: () => Promise<boolean | undefined>;
};

export const useUnclaimedAmt = () => {
  const { provider, account } = useContext(Web3Context);
  const STAKING_CONTRACT_ADDRESS = useStakingContract().data;

  return useQuery(
    ["unclaimedAmt", account, STAKING_CONTRACT_ADDRESS],
    async () => {
      if (!STAKING_CONTRACT_ADDRESS) {
        return;
      }
      if (!provider || !ethers.utils.isAddress(account)) {
        console.log("no provider or wallet provided");
        return;
      }
      const signer = provider.getSigner();

      const STAKING = MultiRewards__factory.connect(
        STAKING_CONTRACT_ADDRESS!,
        signer
      );

      const unclaimedAmt: UnclaimedAmtType[] = [];

      try {
        const votableTokenCount = await (
          await STAKING.rewardTokensCount()
        ).toNumber();
        for (let i = 0; i < votableTokenCount; i++) {
          const tokenAddress = await STAKING.rewardTokens(i);
          const ERC20 = ERC20__factory.connect(tokenAddress, signer);
          const symbol = await ERC20.symbol();
          const decimals = await ERC20.decimals();
          const tokenVotes = await STAKING.earned(account, tokenAddress);
          console.log(symbol, tokenVotes.toString(), decimals);
          console.log(tokenVotes.div(bn(10).pow(decimals)).toString());

          unclaimedAmt.push({
            address: tokenAddress,
            symbol,
            amount: tokenVotes.div(bn(10).pow(decimals)).toString(),
            claim: () =>
              claim({
                account,
                provider,
                STAKING_CONTRACT_ADDRESS: STAKING_CONTRACT_ADDRESS!,
              }),
          });
        }
        return unclaimedAmt;
      } catch (e: any) {
        console.log("TX failed", e.message);
        return;
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );
};

export type ClaimEvent = {
  user: string;
  rewardsToken: string;
  rewardsTokenSymbol: string;
  amount: string;
  timestamp: string;
  tx: string;
};

export const useClaimEvents = () => {
  const { provider, account } = useContext(Web3Context);
  const STAKING_CONTRACT_ADDRESS = useStakingContract().data;

  return useQuery(
    ["claimEvents", account, STAKING_CONTRACT_ADDRESS],
    async () => {
      if (!STAKING_CONTRACT_ADDRESS) {
        return;
      }

      if (!provider || !ethers.utils.isAddress(account)) {
        console.error("No provider or account");
        return;
      }

      const signer = provider.getSigner();
      const STAKING = MultiRewards__factory.connect(
        STAKING_CONTRACT_ADDRESS!,
        signer
      );

      let eventFilter = STAKING.filters.RewardPaid(account, null, null);
      let events = await STAKING.queryFilter(eventFilter);

      let claimEvents: ClaimEvent[] = [];

      for (const event of events) {
        console.log(event, "event");
        const user = event.args[0];
        const rewardsToken = event.args[1];
        const amount = event.args[2];
        const timestamp = await (
          await provider.getBlock(event.blockNumber)
        ).timestamp.toString();
        const tx = event.transactionHash;

        const ERC20 = ERC20__factory.connect(rewardsToken, signer);

        const rewardsTokenSymbol = await ERC20.symbol();
        const decimals = await ERC20.decimals();

        const formattedAmount = amount.div(bn(10).pow(decimals)).toString();

        claimEvents.push({
          user,
          rewardsToken,
          rewardsTokenSymbol,
          amount: formattedAmount,
          timestamp: timestamp,
          tx: tx,
        });
      }

      return claimEvents;
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );
};

/**
 * V2 BUYBACK FUNCTIONS
 *
 * useBuyBackEvents returns a list of all buy back events
 *
 * useTotalBuyBackAmount returns the total amount of buy back tokens
 *
 * BuybackCountdown is a component that displays the time until the next buyback
 *
 * useNextBuyBack returns ETH in wei of next buyback
 *
 * useBuyBackWallet returns ETH in wei of buyback wallet
 *
 * UI Recommendation:
 * - call useBuyBackEvents to get a list of all buy back events
 * - for each event, build a card and display them to the user
 */
export type BuyBackEvent = {
  rewardsToken: string;
  rewardsTokenSymbol: string;
  amount: string;
  timestamp: string;
  tx: string;
};

export const useBuyBackEvents = () => {
  const { provider, account } = useContext(Web3Context);

  return useQuery(
    ["buyBackEvents", account],
    async () => {
      if (!provider || !ethers.utils.isAddress(account)) {
        console.error("No provider or account");
        return;
      }

      const signer = provider.getSigner();
      const Leaderboard = Leaderboard__factory.connect(
        LEADERBOARD_CONTRACT,
        signer
      );

      let eventFilter = Leaderboard.filters.BuybackExecuted(null, null);
      let events = await Leaderboard.queryFilter(eventFilter);

      const buyBackEvents: BuyBackEvent[] = [];

      for (const event of events) {
        const rewardsToken = event.args![0];
        const amount = event.args![1];
        const ERC20 = ERC20__factory.connect(rewardsToken, signer);
        const timestamp = await (
          await provider.getBlock(event.blockNumber)
        ).timestamp.toString();

        const tx = event.transactionHash;

        const rewardsTokenSymbol = await ERC20.symbol();
        const decimals = await ERC20.decimals();

        const formattedAmount = amount.div(bn(10).pow(decimals)).toString();

        buyBackEvents.push({
          rewardsToken,
          rewardsTokenSymbol,
          amount: formattedAmount,
          timestamp: timestamp,
          tx: tx,
        });
      }

      return buyBackEvents;
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );
};

export const useTotalBuyBackAmount = () => {
  const { provider, account } = useContext(Web3Context);

  return useQuery(
    ["totalBuyBackAmount", account],
    async () => {
      if (!provider || !ethers.utils.isAddress(account)) {
        console.error("No provider or account");
        return;
      }

      const signer = provider.getSigner();
      const Leaderboard = Leaderboard__factory.connect(
        LEADERBOARD_CONTRACT,
        signer
      );

      let eventFilter = Leaderboard.filters.BuybackExecuted(null, null);
      let events = await Leaderboard.queryFilter(eventFilter);

      let total: BigNumber = BigNumber.from(0);

      for (const event of events) {
        const amount = event.args![1];
        total = total.add(amount);
      }

      return total;
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );
};

// unused as of rn
export const BuybackCountdown = (): ReactNode => {
  return <Countdown date={new Date(NEXTBUYBACK)} />;
};

export const useNextBuyBack = () => {
  const { provider, account } = useContext(Web3Context);

  return useQuery(
    ["nextBuyBack", account],
    async () => {
      if (!provider || !ethers.utils.isAddress(account)) {
        console.error("No provider or account");
        return;
      }

      return await provider.getBalance(LEADERBOARD_CONTRACT);
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );
};

export const useBuyBackWallet = () => {
  const { provider, account } = useContext(Web3Context);

  return useQuery(
    ["buyBackWallet", account],
    async () => {
      if (!provider || !ethers.utils.isAddress(account)) {
        console.error("No provider or account");
        return;
      }
      const leaderboard = await provider.getBalance(BUYBACK_WALLET);
      const treasury = await provider.getBalance(TREASURY_WALLET);
      return leaderboard.add(treasury);
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );
};

// UTIL
export type StakingData = {
  tokenContract: string;
  stakingContract: string;
  walletBalance: string;
  tokens: string;

  stake: (tokensToStake: BigNumber) => Promise<boolean | undefined>;
  unstake: (tokensToStake: BigNumber) => Promise<boolean | undefined>;
};

export const useStakingData = () => {
  const { provider, account } = useContext(Web3Context);
  const STAKING_CONTRACT_ADDRESS = useStakingContract().data;

  return useQuery(
    ["stakingData", account, STAKING_CONTRACT_ADDRESS],
    async () => {
      if (!STAKING_CONTRACT_ADDRESS) {
        return;
      }

      if (!provider || !ethers.utils.isAddress(account)) {
        console.error("No provider or account");
        return;
      }

      const signer = provider.getSigner();

      const ERC20 = ERC20__factory.connect(TOKEN_CONTRACT, signer);
      const Staking = MultiRewards__factory.connect(
        STAKING_CONTRACT_ADDRESS,
        signer
      );

      const walletBalance = await ERC20.balanceOf(account);
      const tokens = await Staking.balanceOf(account);

      console.log(tokens, "stakinggg");
      console.log(STAKING_CONTRACT_ADDRESS, "stakinggg");

      return {
        tokenContract: TOKEN_CONTRACT,
        stakingContract: STAKING_CONTRACT_ADDRESS!,
        walletBalance: fromWei(walletBalance.toString()),
        tokens: fromWei(tokens.toString()),
        stake: (tokensToStake: BigNumber) =>
          stake({
            voteTokenContract: "0xBD4FbE527cdBBcE02666B7Ec7AE7550a7d27dF6E",
            provider,
            account,
            tokensToStake,
            STAKING_CONTRACT_ADDRESS: STAKING_CONTRACT_ADDRESS!,
          }),
        unstake: (tokensToStake: BigNumber) =>
          unstake({
            provider,
            account,
            tokensToStake,
            STAKING_CONTRACT_ADDRESS: STAKING_CONTRACT_ADDRESS!,
          }),
      } as StakingData;
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );
};

export const useStakingContract = () => {
  const { provider, account } = useContext(Web3Context);

  return useQuery<string | undefined>(
    ["stakingContract", account],
    async () => {
      if (!provider || !ethers.utils.isAddress(account)) {
        console.error("No provider or account");
        return;
      }

      const signer = provider.getSigner();
      const Leaderboard = Leaderboard__factory.connect(
        LEADERBOARD_CONTRACT,
        signer
      );

      try {
        const stakingRewardsContractAddress =
          await Leaderboard.stakingRewards();
        return stakingRewardsContractAddress;
      } catch (e) {
        console.error(e);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );
};

// you can create a loadable component that will show a loading spinner while the data is loading

export const LoadableStakingContract = () => {
  const { data, isLoading } = useStakingContract();

  if (isLoading) {
    // return <Spinner />;
  }

  return <>{data}</>;
};

// always credit where i got code from so in the future its easier to debug as well
// written by @jacobrosenthal

// don't write comments, write code that is easy to understand
