import { useState } from "react";
import {
  Box,
  Button,
  NumberInput,
  Title,
  Tooltip,
  Text,
  Center,
  Loader,
  SegmentedControl,
  Group,
  Badge,
} from "@mantine/core";
import { bn } from "util/utils";
import { StakingData, useStakingData } from "util/v2memedaoWeb3";
//@ts-ignore
import Units from "ethereumjs-units";

const Staking = () => {
  ////
  const [tokensToStake, setTokensToStake] = useState<number>(0);
  const [selected, setSelected] = useState<string>("Stake" || "Unstake");

  const data = useStakingData().data;
  console.log(data);

  const InputLabel = ({ data }: { data: StakingData }) => (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        mb={"sm"}
      >
        <Text>
          <b>MDAI Tokens to {selected == "Stake" ? "Stake" : "Unstake"}</b>
        </Text>
        <Box
          sx={{ display: "flex", flexDirection: "row", position: "relative" }}
        >
          <Text
            sx={{
              ":hover": { cursor: "pointer" },
              position: "absolute",
              right: "10px",
              bottom: "-45px",
              zIndex: 1,
            }}
            onClick={() => {
              if (selected == "Stake") {
                setTokensToStake(parseInt(data.walletBalance.toString()));
              }
              if (selected == "Unstake") {
                setTokensToStake(parseInt(data.tokens.toString()));
              }
            }}
          >
            <Badge
              color="yellow"
              size="lg"
              sx={{ cursor: "pointer" }}
              radius="sm"
            >
              Max
            </Badge>
          </Text>
        </Box>
      </Box>
    </>
  );

  const stakeOnClick = (data: StakingData) => {
    if (selected == "Stake") {
      data.stake(bn(Units.convert(tokensToStake, "eth", "wei")));
      setTokensToStake(0);
    }

    if (selected == "Unstake") {
      data.unstake(bn(Units.convert(tokensToStake, "eth", "wei")));
      setTokensToStake(0);
    }
  };

  ////
  return (
    <>
      {!data && (
        <Center sx={{ height: "20vh" }}>
          <Loader color="yellow" />
        </Center>
      )}
      {data && (
        <>
          {console.log(data)}
          <Title order={2} mb={"md"}>
            Stake
          </Title>
          <Box
            sx={(theme) => ({
              background: theme.colors.dark[9],
              borderRadius: "20px",
              padding: "40px",
              [theme.fn.smallerThan("sm")]: {
                marginLeft: "20px",
                marginRight: "20px",
                paddingLeft: "30px",
                paddingRight: "30px",
              },
            })}
            px={"60px"}
            mx={"100px"}
          >
            <Group
              position="center"
              my="xl"
              sx={{
                fontFamily: "dist",
                ".mantine-SegmentedControl-root": {
                  border: "yellow",
                  outlineStyle: "solid",
                  outlineWidth: "thin",
                  outlineColor: "#F08C00",
                },
              }}
            >
              <SegmentedControl
                value={selected}
                transitionDuration={500}
                transitionTimingFunction="linear"
                onChange={(value: string) => setSelected(value)}
                size="lg"
                color="yellow"
                data={[
                  {
                    value: "Stake",
                    label: (
                      <Center>
                        <Box
                          mx={10}
                          sx={(theme) => ({
                            fontFamily: "dist",
                            [theme.fn.smallerThan("sm")]: {
                              marginLeft: "10px",
                              marginRight: "10px",
                              fontSize: "14px",
                            },
                          })}
                        >
                          Stake
                        </Box>
                      </Center>
                    ),
                  },
                  {
                    value: "Unstake",
                    label: (
                      <Center>
                        <Box
                          mx={10}
                          sx={(theme) => ({
                            fontFamily: "dist",
                            [theme.fn.smallerThan("sm")]: {
                              marginLeft: "10px",
                              marginRight: "10px",
                              fontSize: "14px",
                            },
                          })}
                        >
                          Unstake
                        </Box>
                      </Center>
                    ),
                  },
                ]}
              />
            </Group>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                padding: "10px 20px",
                borderRadius: "10px",
                border: "#2b2b2b solid thin",
                marginBottom: "30px",
              }}
            >
              <Text mr={"sm"}>BALANCE</Text>
              <Text sx={{ color: "gray" }}>
                {Number.parseInt(data.walletBalance.toString())}
              </Text>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                padding: "10px 20px",
                borderRadius: "10px",
                border: "#2b2b2b solid thin",
                marginBottom: "30px",
              }}
            >
              <Text mr={"sm"}>TOTAL STAKED</Text>
              <Text sx={{ color: "gray" }}>{data.tokens}</Text>
            </Box>

            <Box>
              <InputLabel data={data} />
            </Box>
            <NumberInput
              size="md"
              hideControls
              mb={"md"}
              value={tokensToStake == 0 ? undefined : tokensToStake}
              onChange={(val) => setTokensToStake(val || 0)}
              placeholder="0"
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                variant="light"
                onClick={() => {
                  stakeOnClick(data);
                }}
                sx={{ ":disabled": { color: "grey !important" }, width: "40%" }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Staking;
