import React, { useContext } from "react";
import {
  createStyles,
  ThemeIcon,
  Progress,
  Text,
  Group,
  Badge,
  Paper,
  Box,
  Title,
  Button,
} from "@mantine/core";
import { Number1, Number2, Number3 } from "tabler-icons-react";
import { ModalData, TokenType } from "pages/Leaderboard";
import { LeaderboardTableRowProps } from "./Table";

import { unstake } from "util/memedaoWeb3";
import { Web3Context } from "hooks/useWeb3Context";
import { bn, calcPercent, fromWeiNoDecimal } from "util/utils";
import { VotableTokenType } from "util/v2memedaoWeb3";

const abbreviate = require("number-abbreviate");

const ICON_SIZE = 60;

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    overflow: "visible",
    padding: theme.spacing.xl,
    paddingTop: theme.spacing.xl * 1.5 + ICON_SIZE / 3,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },

  icon: {
    position: "absolute",
    top: -ICON_SIZE / 3,
    left: `calc(50% - ${ICON_SIZE / 2}px)`,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },

  title: {
    fontFamily: `${theme.headings.fontFamily},Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
  },
}));

interface PodiumProps extends Omit<LeaderboardTableRowProps, "data"> {
  place: number;
  data: VotableTokenType;
}

export default function PodiumCard({ place, data }: PodiumProps) {
  const { classes } = useStyles();
  console.log("podium data", data);

  const { account, provider } = useContext(Web3Context);

  const [NumberIcon, setNumberIcon] = React.useState(<></>);

  const getNumberIcon = () => {
    const numberSize = 34;
    switch (place) {
      case 1:
        return <Number1 size={numberSize} />;
      case 2:
        return <Number2 size={numberSize} />;
      case 3:
        return <Number3 size={numberSize} />;
      default:
        return <div>error</div>;
    }
  };

  const getColor = () => {
    switch (place) {
      case 1:
        return "yellow";
      case 2:
        return "gray";
      case 3:
        return "red";
    }
  };

  const getGlow = () => {
    let color = "";

    switch (place) {
      case 1:
        color = "0px 0px 146px 35px rgba(224,255,46,0.5)";
        break;
      case 2:
        color = "0px 0px 146px 35px rgba(135,135,135,0.5)";
        break;
      case 3:
        color = "0px 0px 146px 35px rgba(235,44,44,0.5)";
        break;
    }

    return {
      WebkitBoxShadow: color,
      MozBoxShadow: color,
      boxShadow: color,
    };
  };

  React.useEffect(() => {
    const numberSize = 34;
    switch (place) {
      case 1:
        setNumberIcon(<Number1 size={numberSize} />);
        break;
      case 2:
        setNumberIcon(<Number2 size={numberSize} />);
        break;
      case 3:
        setNumberIcon(<Number3 size={numberSize} />);
        break;
      default:
        setNumberIcon(<div>error</div>);
    }
  }, []);

  return (
    <Paper radius="md" withBorder className={classes.card} mt={ICON_SIZE / 3}>
      <ThemeIcon
        className={classes.icon}
        size={ICON_SIZE}
        radius={ICON_SIZE}
        variant="filled"
        color={getColor()}
        sx={getGlow()}
      >
        {getNumberIcon()}
      </ThemeIcon>

      <Text align="center" weight={700} className={classes.title}>
        {data.symbol}
      </Text>
      <Text color="dimmed" align="center" size="sm">
        {data.name}
      </Text>

      <Group position="apart" mt="xs">
        <Text size="sm" color="dimmed">
          Votes
        </Text>
        <Text size="sm" color="dimmed">
          <>
            {data.totalVotes == "NaN" || data.tokenVotes == "NaN"
              ? "0%"
              : calcPercent(data.tokenVotes, data.totalVotes, 2)}
          </>
        </Text>
      </Group>

      <Progress
        value={
          data.totalVotes == "NaN" || data.tokenVotes == "NaN"
            ? 0
            : Number.parseInt(calcPercent(data.tokenVotes, data.totalVotes, 2))
        }
        mt={5}
        animate
        color={getColor()}
        sx={{ borderWidth: 1, borderColor: "gray", borderStyle: "solid" }}
      />

      <Box
        mt={"sm"}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Text>
            <>
              Total Votes:{" "}
              {(
                abbreviate(
                  fromWeiNoDecimal(data.tokenToVotes.toString()),
                  2
                ) as string
              )
                .toString()
                .toUpperCase()}{" "}
              MDAI
            </>
          </Text>
          <Text>
            Your Contribution:{" "}
            {/* if token voted for is the same as the current token */}
            {data.userToToken == data.address
              ? (
                  abbreviate(
                    fromWeiNoDecimal(data.balanceOf.toString()),
                    2
                  ) as string
                )
                  .toString()
                  .toUpperCase()
              : 0}{" "}
            MDAI
          </Text>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
        mt={"sm"}
      >
        <Button
          color={"yellow"}
          variant="light"
          sx={{
            WebkitBoxShadow: "0px 0px 116px 15px rgba(56,255,46,0.9)",
            MozBoxShadow: "0px 0px 116px 15px rgba(56,255,46,0.9)",
            boxShadow: "0px 0px 186px 1px rgba(224,255,46,0.35)",
            flex: 1,
          }}
          m={5}
          size={"md"}
          onClick={() => {
            data.vote();
          }}
        >
          Vote
        </Button>
      </Box>
    </Paper>
  );
}
