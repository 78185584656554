import React from "react";
import {
  createStyles,
  Menu,
  Center,
  Header,
  Container,
  Group,
  Button,
  Burger,
  Text,
  Image,
  Stack,
  Box,
} from "@mantine/core";
import { useBooleanToggle } from "@mantine/hooks";
import { ChevronDown } from "tabler-icons-react";
import { MantineLogo } from "components/MantineLogo";
import { Link } from "react-router-dom";
import { Web3Context } from "hooks/useWeb3Context";
import useWeb3Modal from "hooks/useWeb3Modal";
import { slide as Slide } from "react-burger-menu";
import Sidebar from "./Sidebar";

const HEADER_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  lgo: {
    [theme.fn.smallerThan("sm")]: {
      height: "70px",
      width: "90px",
    },
  },
  lgoText: {
    [theme.fn.smallerThan("sm")]: {
      fontSize: "10px",
    },
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "nowrap",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    [theme.fn.smallerThan("sm")]: {
      fontFamily: theme.headings.fontFamily,
      fontSize: theme.headings.sizes.h3.fontSize,
      marginTop: "20px",
    },

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: 5,
  },
}));

export const WalletButton = ({
  provider,
  loadWeb3Modal,
  logoutOfWeb3Modal,
}: {
  provider: any;
  loadWeb3Modal: any;
  logoutOfWeb3Modal: any;
}) => {
  const {
    account,
    setAccount,
    setProvider,
    // loadWeb3Modal: contextLoad,
    // logoutOfWeb3Modal: contextLogout,
    // provider: contextProvider,,
  } = React.useContext(Web3Context);
  const [rendered, setRendered] = React.useState("");
  const [refetch, setRefetch] = React.useState(false);

  // cache modals
  // if (contextProvider) {
  //   console.log("exists");
  //   provider = contextProvider;
  //   loadWeb3Modal = contextLoad;
  //   logoutOfWeb3Modal = contextLogout;

  //   console.log(loadWeb3Modal, logoutOfWeb3Modal);
  //   console.log(contextProvider);
  // }

  React.useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }
        setProvider(provider);
        // setLoadWeb3Modal(() => loadWeb3Modal);
        // setLogoutOfWeb3Modal(() => logoutOfWeb3Modal);
        // https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
        // if (provider && !loadWeb3Modal) {
        //   setLoadWeb3Modal(() => loadWeb3Modal);
        //   setLogoutOfWeb3Modal(() => logoutOfWeb3Modal);
        // }

        // Load the user's accounts.
        const accounts = await provider.listAccounts();
        setAccount(accounts[0].toLowerCase());

        // Resolve the ENS name for the first account.
        const name = await provider.lookupAddress(accounts[0]);

        // const name = "";

        // Render either the ENS name or the shortened account address.
        if (name) {
          setRendered(name);
        } else {
          setRendered(account.substring(0, 6) + "..." + account.substring(36));
        }
      } catch (err) {
        setAccount("");
        setRendered("");
        console.log("mm error");
        console.error(err);
      }
    }
    fetchAccount();
  }, [
    account,
    provider,
    setAccount,
    setRendered,
    setProvider,

    loadWeb3Modal,

    logoutOfWeb3Modal,
    refetch,
  ]);

  // React.useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     //assign interval to a variable to clear it.
  //     setRefetch(!refetch);
  //   }, 500);

  //   return () => clearInterval(intervalId); //This is important
  // }, [refetch]);

  return (
    <Button
      radius="xl"
      variant="light"
      sx={{ height: 30 }}
      onClick={() => {
        if (!provider) {
          console.log(loadWeb3Modal);
          loadWeb3Modal();
        } else {
          console.log(logoutOfWeb3Modal);
          logoutOfWeb3Modal();
        }
      }}
    >
      {rendered === "" && "Connect Wallet"}
      {rendered !== "" && rendered}
    </Button>
  );
};
interface HeaderActionProps {
  links: {
    link: string;
    label: string;
    links?: { link: string; label: string }[];
  }[];
}

export function HeaderAction({ links }: HeaderActionProps) {
  // const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
  const { loadWeb3Modal, logoutOfWeb3Modal, provider } =
    React.useContext(Web3Context);

  const { classes } = useStyles();
  const [opened, toggleOpened] = useBooleanToggle(false);
  const items = links.map((link) => {
    const menuItems = link.links?.map((item) => (
      <Menu.Item key={item.link}>{item.label}</Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu
          key={link.label}
          trigger="hover"
          delay={0}
          transitionDuration={0}
          placement="end"
          gutter={1}
          control={
            <a
              href={link.link}
              className={classes.link}
              onClick={(event) => event.preventDefault()}
            >
              <Center>
                <span className={classes.linkLabel}>{link.label}</span>
                <ChevronDown size={12} />
              </Center>
            </a>
          }
        >
          {menuItems}
        </Menu>
      );
    }

    return (
      <Link
        key={link.label}
        to={link.link}
        className={classes.link}
        onClick={() => {
          if (opened) {
            toggleOpened();
          }
        }}
      >
        {link.label}
      </Link>
    );
  });

  return (
    <>
      <Slide
        isOpen={opened}
        onClose={() => {
          toggleOpened();
        }}
        // noOverlay
        // disableOverlayClick
        customBurgerIcon={false}
      >
        <Sidebar />
        {/* <Box
          className={classes.inner}
          sx={(theme) => ({
            height: "100%",
            width: "100%",
            backgroundColor: theme.colors.dark[5],
            top: -20,
            position: "absolute",
            display: "flex !important",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: `${theme.fontSizes.lg} !important`,
          })}
        >
          {items}
        </Box> */}
      </Slide>
      <Header
        height={HEADER_HEIGHT}
        sx={{ borderBottom: 0 }}
        mb={40}
        mt={20}
        px={5}
      >
        <Container className={classes.inner} fluid>
          <Group sx={{ flex: 1, flexWrap: "nowrap" }}>
            <Burger
              opened={opened}
              onClick={() => toggleOpened()}
              className={classes.burger}
              size="sm"
            />
            <a
              href={"https://meme-dao.ai/"}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                marginLeft: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                radius="md"
                height={90}
                src="/logo.png"
                alt="logo"
                fit="contain"
                className={classes.lgo}
                sx={{ marginTop: "20px" }}
              />
              <Text
                className={classes.lgoText}
                sx={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: "13px",
                }}
              >
                Artificial Intelligence At Its Core
              </Text>
            </a>
          </Group>
          <Group
            spacing={5}
            className={classes.links}
            sx={{
              flex: 1,
              flexWrap: "nowrap",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* {items} */}
          </Group>
          <span
            style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}
          >
            <WalletButton
              provider={provider}
              loadWeb3Modal={loadWeb3Modal}
              logoutOfWeb3Modal={logoutOfWeb3Modal}
            />
          </span>
        </Container>
      </Header>
    </>
  );
}
