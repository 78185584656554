import { FooterLinks } from "components/Footer";
import { HeaderAction } from "components/Header";
import { FOOTER_DATA, HEADER_DATA } from "util/constants";

import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Leaderboard from "pages/Leaderboard";
import Staking from "pages/Staking";
import Claim from "pages/Claim";
import History from "pages/History";
import NotFound from "pages/NotFound";
import { AppShell, BackgroundImage, Container } from "@mantine/core";
import { LoadingContext } from "hooks/useLoadingContext";
import { Web3Context } from "hooks/useWeb3Context";
import { useContext, useState } from "react";

import { Web3Provider } from "@ethersproject/providers";
import Loader from "components/Loader/Loader";

import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import PleaseConnectWallet from "pages/PleaseConnectWallet";
import useWeb3Modal from "hooks/useWeb3Modal";
import React from "react";
import Home from "pages/Home";
import Sidebar from "components/Sidebar";
import MainSidebar from "components/MainSidebar";
import ComingSoon from "pages/Coming Soon";
const queryClient = new QueryClient();

function App() {
  const { account } = useContext(Web3Context);
  const { pathname } = useLocation();
  React.useEffect(() => {
    console.log(pathname);
    console.log(":");
    if (pathname === "/") {
      window.location.replace(window.location + "leaderboard");
    }
  }, [pathname]);
  return (
    <div className="App">
      <AppShell
        header={<HeaderAction links={HEADER_DATA} />}
        navbar={<MainSidebar />}
        sx={{
          height: "100%",
          main: {
            height: "87vh",
            padding: "0",
          },
        }}
      >
        <BackgroundImage
          src="/ghost1.png"
          sx={{
            backgroundPosition: "left",
            backgroundSize: "350px",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container my="md" sx={{ minHeight: "87vh", height: "100%" }}>
            <Routes>
              {account && (
                <>
                  <Route path="/" element={<Home />} />
                  <Route path="stake" element={<Staking />} />
                  <Route path="claim" element={<Claim />} />
                  <Route path="buyback" element={<History />} />
                  <Route path="leaderboard" element={<Leaderboard />} />

                  <Route path="nft" element={<ComingSoon />} />
                  <Route path="whitepaper" element={<ComingSoon />} />
                </>
              )}
              {!account && <Route path="*" element={<PleaseConnectWallet />} />}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
        </BackgroundImage>
      </AppShell>

      {/* <FooterLinks data={FOOTER_DATA} /> */}
    </div>
  );
}

function ContextWrapper() {
  const [wprovider, wloadWeb3Modal, wlogoutOfWeb3Modal] = useWeb3Modal();
  console.log(wprovider, "initial provider");

  const [account, setAccount] = useState<string>("");
  const [provider, setProvider] = useState<Web3Provider | undefined>(
    wprovider as Web3Provider
  );

  const [loadWeb3Modal, setLoadWeb3Modal] = useState<() => Promise<void>>(
    () => wloadWeb3Modal as () => Promise<void>
  );
  const [logoutOfWeb3Modal, setLogoutOfWeb3Modal] = useState<
    () => Promise<void>
  >(() => wlogoutOfWeb3Modal as () => Promise<void>);

  const [loading, setLoading] = useState<boolean>(false);

  // https://stackoverflow.com/questions/59858019/createcontext-using-a-dynamic-object
  // https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
  // what i searched: dynamic initial context react provider
  React.useEffect(() => {
    setProvider(wprovider as Web3Provider);
  }, [wprovider]);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <QueryClientProvider client={queryClient}>
        <Web3Context.Provider
          value={{
            account,
            setAccount,
            provider,
            setProvider,
            loadWeb3Modal,
            logoutOfWeb3Modal,
            setLoadWeb3Modal,
            setLogoutOfWeb3Modal,
          }}
        >
          {!loading || <Loader />}
          <App />
        </Web3Context.Provider>
      </QueryClientProvider>
    </LoadingContext.Provider>
  );
}

export default ContextWrapper;
