import {
  Box,
  Button,
  Center,
  Container,
  createStyles,
  Divider,
  Group,
  Loader,
  ScrollArea,
  Space,
  Table,
  Text,
  TextInput,
  Title,
  UnstyledButton,
} from "@mantine/core";
// import ClaimTable from "components/ClaimTable";
import { BigNumber } from "ethers";
import React, { useState } from "react";

import Fade from "react-reveal/Fade";
import {
  ClaimEvent,
  UnclaimedAmtType,
  useClaimEvents,
  useUnclaimedAmt,
} from "util/v2memedaoWeb3";

import Web3Utils, { fromWei } from "web3-utils";
import { ChevronUp, ChevronDown, Selector, Search } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
  item: {
    ...theme.fn.focusStyles(),

    display: "flex",
    alignItems: "center",
    borderRadius: theme.radius.md,
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: `${theme.spacing.sm}px ${theme.spacing.xl}px`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,
    marginBottom: theme.spacing.sm,
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  symbol: {
    fontSize: 30,
    fontWeight: 700,
    width: 60,
  },
  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

const ClaimTable = ({ data }: { data: UnclaimedAmtType[] }) => {
  const { classes, cx } = useStyles();

  const items = data.map((item, index) => (
    <div
      className={cx(classes.item, {
        [classes.itemDragging]: false,
      })}
    >
      <Container mx={0} fluid sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Title order={2}>Unclaimed {item.symbol}</Title>
          </Box>
          <Box sx={{ flexWrap: "nowrap", whiteSpace: "nowrap" }}>
            <Title
              order={4}
              sx={(theme) => ({ color: theme.colors.dark[2] })}
            ></Title>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Box>
              <Title
                order={2}
                sx={(theme) => ({ color: theme.colors.dark[2] })}
              >
                {/* {Web3Utils.fromWei(item.amount.toString())} */}
                {item.amount}
              </Title>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  ));
  return <div>{items}</div>;
};

export type ClaimType = {
  symbol: string;
  unClaimedAmt: BigNumber;
  stakingContract: string;
};

const Claim = () => {
  const unclaimed = useUnclaimedAmt();
  const claimEvents = useClaimEvents();

  const unclaimedLoaded = !unclaimed.isLoading;
  const claimedLoaded = !claimEvents.isLoading;

  return (
    <>
      <Title mb={"md"}>Claim</Title>
      {!unclaimedLoaded && (
        <Center sx={{ height: "20vh" }}>
          <Loader color="yellow" />
        </Center>
      )}
      {unclaimedLoaded && (
        <Fade>
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color={"yellow"}
                mb={10}
                variant="light"
                sx={{
                  WebkitBoxShadow: "0px 0px 116px 15px rgba(56,255,46,0.9)",
                  MozBoxShadow: "0px 0px 116px 15px rgba(56,255,46,0.9)",
                  boxShadow: "0px 0px 186px 1px rgba(224,255,46,0.35)",
                }}
                size={"md"}
                onClick={async () => {
                  if (!unclaimed.data) return;
                  await unclaimed.data[0].claim();
                  console.log("successful claim");
                }}
              >
                Claim All
              </Button>
            </Box>
            {unclaimed.data && <ClaimTable data={unclaimed.data} />}
            {!unclaimed.data && (
              <Center sx={{ height: "20vh" }}>
                <Title>No unclaimed rewards</Title>
              </Center>
            )}
          </>
        </Fade>
      )}
      <Space h="md" />
      <Divider size="sm" color="yellow" />
      <Space h="md" />
      <Title mb={"md"}>Claim History</Title>
      {!claimedLoaded && (
        <Center sx={{ height: "20vh" }}>
          <Loader color="yellow" />
        </Center>
      )}
      {claimedLoaded && (
        <Fade>
          <>
            {claimEvents.data && <ClaimHistoryTable data={claimEvents.data} />}
            {!claimEvents.data && (
              <Center sx={{ height: "20vh" }}>
                <Title>No unclaimed rewards</Title>
              </Center>
            )}
          </>
        </Fade>
      )}
    </>
  );
};

export default Claim;
///////

////
interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  onSort(): void;
  sorted: boolean;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <Text weight={500} size="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: ClaimEvent[], search: string) {
  const keys = Object.keys(data[0]);
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    // how to overcome this w/o casting as any?
    keys.some((key) => (item as any)[key].toLowerCase().includes(query))
  );
}

function sortData(
  data: ClaimEvent[],
  payload: { sortBy: keyof ClaimEvent; reversed: boolean; search: string }
) {
  if (!payload.sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[payload.sortBy].localeCompare(a[payload.sortBy]);
      }

      return a[payload.sortBy].localeCompare(b[payload.sortBy]);
    }),
    payload.search
  );
}
/////
function ClaimHistoryTable({ data }: { data: ClaimEvent[] }) {
  console.log(data, "claim");
  const [search, setSearch] = useState("");
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState<keyof ClaimEvent>("timestamp");
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof ClaimEvent) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(data, { sortBy, reversed: reverseSortDirection, search: value })
    );
  };

  const rows = sortedData.map((row) => (
    <tr key={row.user + row.timestamp}>
      <td>{`${new Date(
        Number.parseInt(row.timestamp) * 1000
      ).toDateString()}`}</td>
      <td>{row.rewardsTokenSymbol}</td>

      <td>{row.amount}</td>
      <td>
        <Text
          variant="link"
          component="a"
          href={`https://etherscan.io/tx/${row.tx}`}
        >
          {row.tx.substring(0, 10) + "..."}
        </Text>
      </td>
    </tr>
  ));

  if (data.length === 0) {
    return <Center>No claim history... yet :(</Center>;
  }

  return (
    <ScrollArea>
      <TextInput
        placeholder="Search by any field"
        mb="md"
        icon={<Search size={14} />}
        value={search}
        onChange={handleSearchChange}
      />
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        sx={{ tableLayout: "fixed", minWidth: 700 }}
      >
        <thead>
          <tr>
            <Th
              sorted={sortBy === "timestamp"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("timestamp")}
            >
              Timestamp
            </Th>
            <Th
              sorted={sortBy === "rewardsTokenSymbol"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("rewardsTokenSymbol")}
            >
              Token Claimed
            </Th>

            <Th
              sorted={sortBy === "amount"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("amount")}
            >
              Value
            </Th>
            <Th
              sorted={sortBy === "tx"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("tx")}
            >
              Tx
            </Th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              <td colSpan={Object.keys(data[0]).length}>
                <Text weight={500} align="center">
                  Nothing found
                </Text>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}
