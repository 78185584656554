import classNames from "classnames";
import { FC } from "react";
// import { BasicProps } from '../../types';
import styles from "./Loader.module.scss";
import Fade from "react-reveal/Fade";

import { Triangle } from "react-loader-spinner";
import "./tri.css";
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Loader: FC = () => {
  return (
    <Fade>
      <div className={classNames(styles.container)}>
        <div
          style={{
            fontSize: "24px",
            color: "white",
            width: "50vw",
            textAlign: "center",
          }}
        >
          APPROVING FUNDS, DO NOT LEAVE THIS PAGE
        </div>
        <Triangle color={"white"} />
      </div>
    </Fade>
  );
};

export default Loader;
